/* @font-face {
  font-family: Segoe UI;
  src: url(./assets/fontFamily/segoe-ui-4-cufonfonts-webfont/Segoe\ UI.woff);
} */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  overflow: auto;
  width: 100%;
  height: 100vh;
}
.seperator {
  padding: 0 !important;
}
.scroll__null::-webkit-scrollbar {
  /* scrollbar-width: thin; */
  width: 0px;
  border-radius: 100%;
  display: none !important;
}

.scroll__remove {
  -ms-overflow-style: none; /* IE and Edge */
  /* scrollbar-width: thin; */
  overflow: hidden;
}
.scroll__remove::-webkit-scrollbar {
  scrollbar-width: thin;
  /* width: 6px; */
  border-radius: 100%;
}
.scroll__remove::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll__remove::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scroll__remove::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.scroll__remove::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  overflow: hidden;
}
.scroll__remove::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.tablegrid {
  overflow-x: hidden;
  height: 55vh;
}
.tablegrid:hover {
  overflow: auto;
}
.input {
  border: none;
  outline: none;
}
.autocomplete__edit {
  z-index: 1000;
}
#fluent-default-layer-host {
  z-index: 1 !important;
}

:root {
  /* --main-bg-color:blue;
  --border-color:#2196f3; */
}

.calendar {
  display: flex;
  /* border:1px solid var(--border-color); */
  flex-direction: column;
  width: 100%;
  margin: 20px auto 0 auto;
  max-width: 1200px;
  background-color: #fff !important;
}
.calendar .calendar-header {
  display: flex;
  justify-content: space-between;
  height: 50px;
  background-color: #c4d0f2;
  align-items: center;
  color: black;
  padding: 0 15px;
  /* border-bottom: 1px solid var(--border-color); */
}
.calendar .row {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.calendar .row .day {
  display: flex;
}
.calendar .row .day .week-day {
  flex: 1;
  line-height: 30px;
  /* background-color:white; */
  color: black;
  font-weight: 600;
  margin-left: 30px !important;
  /* background-color: #FAFAFB !important; */
}
.calendar .date-row {
  display: flex;
  border-collapse: collapse;
}
.calendar .date-row .day {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.calendar .date-row span:first-child {
  width: 100%;
  height: 100%;
  text-align: center;
  color: #000;
  border: "none";
  border: 1px solid #f8f9fd;
  /* padding-top: 40px; */
  /* position: relative;
  top: 30px;
  
} */
}
.calendar .date-row .day .event {
  font-weight: 800 !important;
}
.f1qq5vh1 > .fui-Checkbox__indicator {
  border-bottom-color: #268581 !important;
}

.f13mj44e > .fui-Checkbox__indicator {
  border-left-color: #268581 !important;
}

.fub94a2 > .fui-Checkbox__indicator {
  border-right-color: #268581 !important;
}
.f68vbr6 {
  border-bottom-color: #268581 !important;
}
.ftovhe4 {
  border-right-color: #268581 !important;
}
.ftywsgz {
  background-color: #268581 !important;
}
.f1wczvin {
  border-left-color: #268581 !important;
}

.f3xi7mh {
  border-top-color: #268581 !important;
}

.f1gqj827 > .fui-Checkbox__indicator {
  border-top-color: #268581 !important;
}
.modal {
  background-color: rgba(51, 51, 51, 0.6);
}
.modal.fade.show {
  display: block;
}
.modal-dialog {
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%) !important;
}
.modal-dialog button.close {
  background-color: #fff;
  z-index: 12;
  position: relative;
  top: 35px;
  right: 15px;
  pointer-events: stroke;
}
input.error {
  border-color: red;
}
small.error {
  color: red !important;
  text-align: left;
}
.calendar .date-row span:first-child {
  padding-top: 10px !important;
}
.calendar .date-row .day,
.calendar .date-row .emptySlot {
  height: 87px !important;
  width: 87px !important;
  max-width: 88 !important;
  flex: 0;
}
.search-box-icon-red .ms-Icon {
  color: #268581 !important;
}

.red-checkbox input[type="checkbox"] + .MuiSvgIcon-root {
  color: red;
}

.MuiAppBar-root {
  background-color: #268581 !important;
  /* height: 80px !important; */
  /* color: black;
  border: none !important;
  border-radius: 15px 0px 0px 15px; */
  /* height:70px;
  width:70%; */
  /* z-index: -1; */
}

/* .MuiAppBar-positionFixed {
  left: 16rem !important;
  box-shadow: none !important;
} */

[class^="overlay-"] {
  background: rgba(255, 255, 255) !important;
}

[class^="overlay-"] .ms-Overlay {
  background: rgba(255, 255, 255) !important;
}

::-webkit-scrollbar {
  width: 10px; /* Adjust this value to make it thinner or wider */
  height: 10px; /* Set the height to 0 to make it horizontal */
}

/* Track (the background of the scrollbar) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}
::-webkit-scrollbar-horizontal {
  height: 1px; /* Set the height to change the width */
}

/* Handle (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: #aba6a6; /* Color of the scrollbar handle */
  border-radius: 6px; /* Rounded corners of the scrollbar handle */
}

/* When hovering over the scrollbar */
::-webkit-scrollbar-thumb:hover {
  background: #8f8c8c; /* Color of the scrollbar handle on hover */
}

.f5opsb9 > .fui-Checkbox__indicator {
  background-color: #268581 !important;
}
.fvvg2h8 > .fui-Checkbox__indicator {
  color: #268581 !important;
}

.r1jtohuq::after {
  border-bottom: #268581 !important;
}

.r1mol2m5 {
  background-color: white !important;
  border: 1px solid white !important;
}

.f1jc6hxc::after {
  border-bottom-color: white !important;
}

.css-zwyrdd-MuiTabs-indicator {
  background-color: #268581 !important;
}
.css-1dm1d35-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #268581 !important;
}

.r1jtohuq::after{
  display: none !important;
}