body {
  margin: 0px;
}

body .makeStyles-adminRoot-1,
html .makeStyles-adminRoot-1,
.MuiPopover-root,
.MuiDialog-paper,
.Toastify,
.MuiDialog-paperFullScreen {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.medentry {
  border-bottom: 1px solid red !important;
}

.medentry:before {
  border-bottom: 1px solid red !important;
}

.medentry:hover {
  border-bottom: 1px solid red !important;
}

.medentry:after {
  border-bottom: 1px solid red !important;
}

@media only screen and (min-width: 960px) {
  .imageViwer {
    display: flex !important;
    overflow: auto !important;
    height: 80vh !important;
  }

  .imageCard {
    height: 820px;
    width: 100%;
    overflow: auto;
  }
}
@media only screen and (max-width: 960px) {
  .nextIcon {
    position: absolute;
    z-index: 1;
    right: 0%;
    top: 50%;
  }
  .prevIcon {
    position: absolute;
    z-index: 1;
    left: 0%;
    top: 50%;
  }
}
@media only screen and (min-width: 960px) {
  .nextIcon {
    position: absolute;
    z-index: 1;
    right: 52%;
    top: 60%;
  }
  .prevIcon {
    position: absolute;
    z-index: 1;
    top: 60%;
  }
}

@media only screen and (max-width: 960px) {
  .imageViwer {
    display: flex !important;
    overflow: auto !important;
    height: 300px !important;
  }

  .formViwer {
    // display: flex !important;
    overflow: auto !important;
    height: 300px !important;
  }

  .imageCard {
    height: 500px;
    width: 100%;
    overflow: auto;
  }
}

// User View Tab fixed for different devices

@media only screen and (max-width: 960px) {
  .userTableViewTabs {
    margin-top: 80px;
    // position: relative;
    // left: 0%;
  }
}

@media only screen and (min-width: 960px) {
  .userTableViewTabs {
    margin-top: 80px;
    margin-left: 270px;
    // position: relative;
    // left: 25%;
  }
}

.DayPicker-wrapper {
  width: max-content !important;
}

.customLabelWidth .MuiFormLabel-root {
  width: max-content !important;
}

.MuiPickerDTToolbar-toolbar .MuiButton-label {
  padding: 0 !important;
}

.prescriptionImageStyle .MuiTab-wrapper {
  padding: 2px;
  background-image: linear-gradient(
    30deg,
    #fc575e 0%,
    #f7b42c 15%,
    #f7b42c 100%
  );
}

.prescriptionImageStyle .Mui-selected .MuiTab-wrapper {
  background-image: linear-gradient(
    30deg,
    #6da5de 0%,
    #6da5de 15%,
    #7de3dc 100%
  );
}
.dummyPage .MuiListItemIcon-root {
  min-width: 32px !important;
  // margin-top: 10px !important;
}

.processCaseEditTab .MuiTabs-scrollButtons {
  background-color: #fff !important;
  border-radius: 70% !important;
  color: #000 !important;
}

.MuiTab-textColorInherit {
  min-width: auto !important;
}

.MuiTab-textColorInherit .Mui-selected {
  min-width: auto !important;
}
.ms-SearchBox {
  border-radius: 24px !important;
  outline: none !important;
}

.ms-SearchBox::after {
  border: none !important;
}
.ms-Panel-footerInner {
  padding-left: 0;
  padding-right: 0;
}
.fui-DatePicker__popupSurface {
  border: none !important;
  background-color: white !important;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.14) !important;
}
.MuiAutocomplete-popup {
  z-index: 999999 !important;
}
.ms-ContextualMenu-icon {
  align-items: center;
  display: flex;
  font-size: 21px;
}
.ms-ContextualMenu-Callout {
  padding: 8px 0;
  gap: 8px !important;
  background-color: #ffffff !important;
  box-shadow: 0px 6.400000095367432px 14.399999618530273px 0px
      rgba(0, 0, 0, 0.13),
    0px 1.2000000476837158px 3.5999999046325684px 0px rgba(0, 0, 0, 0.1) !important;
  border-radius: 2px !important;
  overflow: hidden !important;
}
.ms-ContextualMenu-itemText {
  // font-size: 14px !important;
  font-weight: 400 !important;
  color: #605e5c !important;
}
.context__delete .ms-ContextualMenu-itemText {
  color: #e33b1b !important;
}
.ms-Panel-footerInner {
  background-color: white !important;
}
.ms-Panel-footer {
  z-index: 999999999999999 !important;
}
.ms-Panel-content {
  padding-left: 0;
  padding-right: 0;
}
.input__Style {
  border-radius: 8px !important;
  border: 1px solid #e5e5e5 !important;
  overflow: hidden !important;
  font-size: 14px !important;
  margin-top: "4px" !important;
  background-color: white !important;
}
.textbox__Style {
  border-radius: 0px !important;
  border: 1px solid #e5e5e5 !important;
  overflow: hidden !important;
  font-size: 14px !important;
  margin-top: "4px" !important;
  background-color: white !important;
}
.ms-Dialog-inner {
  padding: 0;
}
.ms-Dialog-title {
  padding: 0 !important;
  min-height: 0 !important;
}
.notistack-MuiContent .go1141946668 {
  // this is for the notistack
  z-index: 1000000000000000 !important;
}
#fluent-default-layer-host {
  z-index: 1 !important;
}
// .se-btn {
//   width: 20px !important;
//   height: 20px !important;
// }
.se-btn {
  border: none !important;
}
.se-btn svg {
  width: 12px !important;
  height: 12px !important;
}
.txt {
  font-family: "Segoe UI";
}
// .ms-SearchBox
